<template>
	<MhRouterView class="OvSearchResultsView view background2--color-primary-background" :isContentReady="isContentReady">

		<div class="minHeight minHeight--100 vSpace vSpace--viewContent">

			<OvMainHeadline
				:isSearchLayout="true"
			></OvMainHeadline>

			<OvSearchResults></OvSearchResults>
			<!--
			-->

			<br/>
			<br/>
			<br/>

		</div>
		<OvMainFooter></OvMainFooter>

		<!--
		<pre data-name="page">{{page}}</pre>
		-->
	</MhRouterView>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import MhRouterView from '@/components/MhRouterView/MhRouterView.vue'
	import OvMainHeadline from './../components/OvMainHeadline.vue'
	import OvSearchResults from '@/components/OvSearchResults.vue'
	import OvMainFooter from '@/components/OvMainFooter.vue'

	export default {
		name: 'OvSearchResultsView',
		components: {
			MhRouterView,
			OvMainHeadline,
			OvSearchResults,
			OvMainFooter,
		},
		props: {},
		data() {
			return {
				isContentReady : true,
			}
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
		},
		methods: {},
		mounted(){},
	}
</script>

<style lang="less" scoped>
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";

	.OvSearchResultsView {}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
