<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<OvSearchResults></OvSearchResults>

	2020-11-23	init

-->

<template>
	<div class="OvSearchResults contentModule" :class="elmClasses">
		<div class="contentModule__inner hSpace hSpace--app maxWidth maxWidth--app" >
			<!--
			<pre name="searchString">{{searchString}}</pre>
			-->
			<template v-if="searchString">
				<br/>
				<br/>

				<div class="OvSearchResults__group" v-for="(group, index) in resultGroups" :key="'g'+index">
					<h2 class="OvSearchResults__groupTitle font font--bold font--sizeMini lineCrop">
						{{$t('title.resultsFor')}} {{$t('title.' + group.label)}}

						<span class="OvSearchResults__groupCountPosts" :class="{'OvSearchResults__groupCountPosts--isHidden' : group.isFetching}">
							{{group.posts.length}}
						</span>
					</h2>
					<div class="OvSearchResults__groupPosts font font--sizeMedium"
						 :class="['xl', 'lg', 'dt'].includes($mq) ? 'font--sizeMedium' : 'font--sizeDefault'">
						<div class="OvSearchResults__groupPost" v-if="group.isFetching">
							<MhSpinner></MhSpinner> &nbsp;
						</div>
						<router-link
							class="OvSearchResults__groupPost flex flex--middle hover hover--listItem"
							v-for="(post, index) in group.posts" :key="'p'+index"
							:to="{ name: group.gotoView, params: { slug : post.slug } }">
							<OvLabel2
								:class="['xl', 'lg', 'dt'].includes($mq) ? 'font--sizeDefault' : 'font--sizeSmall'"
								:text="app.getPostLabelText( post )"
								:verticalAlign="'middle'"
							></OvLabel2>
							<strong class="OvSearchResults__groupPostTitle flex flex--grow"><span v-html="app.getPostTitle( post, false )"></span></strong>
							<span class="OvSearchResults__groupPostArrowIcon linkArrow font font--sizeDefault">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.08 21">
								  <path id="Pfad_64" data-name="Pfad 64" d="M2.55-11.433H21.3L15-17.862l2.34-2.418L27.63-9.78,17.34.72,15-1.7l6.3-6.429H2.55Z" transform="translate(-2.55 20.28)" fill="rgba(0,0,0,0.97)"/>
								</svg>
							</span>
						</router-link>
						<div class="OvSearchResults__groupPost OvSearchResults__groupPost--noResults" v-if="group.posts.length < 1 && !group.isFetching">
							{{$t('text.noSearchResults')}}
						</div>
					</div>
					<!--
					<pre name="group.posts">{{group.posts}}</pre>
					-->
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import RestHandler from '@/components/RestHandler/RestHandler.js'

	import MhSpinner from '@/components/MhSpinner/MhSpinner.vue'
	import OvHeadline from '@/components/OvHeadline.vue'
	import OvLabel2 from '@/components/OvLabel2.vue'

	export default {
		name: 'OvSearchResults',
		components: {
			MhSpinner,
			OvHeadline,
			OvLabel2,
		},
		mixins: [ RestHandler ],
		props: {},
		data() {
			return {
				resultGroups : [
					{
						label : 'pages',
						restRoute : '/wp-json/mh/v1/posts',
						restParams : {
							search  : this.searchString,
							postType : 'page',
							perPage : 99,
						},
						gotoView : 'OvPageView',
						posts : [],
						isFetching : false,
					},
					{
						label : 'exhibitions',
						restRoute : '/wp-json/mh/v1/posts',
						restParams : {
							search  : this.searchString,
							postType : 'ausstellungen_post',
							perPage : 99,
						},
						gotoView : 'OvExhibitionView',
						posts : [],
						isFetching : false,
					},
					{
						label : 'events',
						restRoute : '/wp-json/mh/v1/events',
						restParams : {
							search  : this.searchString,
							range : 'nowAndFuture',
							perPage : 99,
						},
						gotoView : 'OvEventView',
						posts : [],
						isFetching : false,
					},
				]
			}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			searchString(){
				return this.app.searchString
			},
		},
		methods: {
			isPostMatchLanguage( post, lang, searchString, doLog = false ){
				let isMatching = false
				const baseKeys = [
					'title.rendered',
					'acf.pageTitle__' + lang,
					'acf.contentModules__' + lang + '.contentModules',
				]

				if( doLog ){
					console.group( this.$options.name + ' • isPostMatchLanguage( post, lang, searchString )', lang)
					console.log('searchString:', searchString)
					console.log('baseKeys:', baseKeys)
				}

				// walk the baseKeys,
				// transform to json-string and check if string contains searchString
				this._.forEach( baseKeys, (baseKey)=>{
					const value = this._.get( post, baseKey, false )
					const jsonValue = value ? JSON.stringify( value ).toLowerCase() : ''
					const hasSearchString = jsonValue.indexOf( searchString.toLowerCase() ) > -1 ? true : false

					if( doLog ){
						console.group( baseKey)
						console.log('value:', value)
						console.log('jsonValue:', jsonValue)
						console.log('hasSearchString:', hasSearchString)
						console.groupEnd()
					}

					if( hasSearchString ) isMatching = true
				})

				if( doLog ){
					console.groupEnd()
				}

				return isMatching
			},
			fetchResults( callback = ()=>{}, doLog = false ){
				const searchString = this.searchString

				if( doLog ){
					console.groupCollapsed('OvSearchResultsView • fetchPosts() • before')
					console.log('searchString:', searchString)
					console.groupEnd()
				}

				if( !this._.isEmpty( searchString ) ){
					this._.forEach( this.resultGroups, (group)=>{
						group.posts = []
						group.isFetching = true

						this.restHandler__fetch({
							action   : 'GET',
							route    : group.restRoute,
							params   : group.restParams,
							callback : (response) => {
								const result = response.data.result
								const posts  = []

								this._.forEach(result, (post)=>{
									if( this.isPostMatchLanguage( post, this.app.lang, this.searchString ) ){
										posts.push( post )
									}
								})

								group.isFetching = false
								group.posts = posts

								if( doLog ){
									console.groupCollapsed('OvSearchResultsView • fetchPosts() callback')
									console.log('result:', result)
									console.groupEnd()
								}

								callback()
							},
						})
					})
				}
			},
		},
		created(){
			EventBus.$on('changeSearchTerm', (newTerm)=>{
				this.fetchResults()

				//console.log(this.$options.name + ' • on changeSearchTerm', newTerm)
			})
		},
		mounted(){
			this.fetchResults()
		},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.OvSearchResults { // layout
		&__group {
			.grid;
			.grid--colGap;
			.grid--setAreas;

			@media @mq[dt] { .grid--cols-12; }
			@media @mq[md] { .grid--cols-8; }
			@media @mq[sm] { .grid--cols-8;  }

			grid-template-areas: "a a a a a a a a a a a a"
								 "b b b b b b b b b b b b";

			@media @mq[md] {
				grid-template-areas: "a a a a a a a a"
									 "b b b b b b b b";
			}
		}
	}
	.OvSearchResults { // styling
		&__group {
			//border-top: 3px solid black;
			//padding-bottom: 1.75em;

			&Title {
				padding-top: 2.6em;
				padding-bottom: 0.5em;
				border-bottom: 3px solid black;
			}
			&CountPosts {
				//background-color: fade( red, 10 );
				display: inline-block;
				font-size: 0.5em;
				line-height: 0.5em;
				transform: translateY(-0.75em);
				display: none;

				&--isHidden {
					opacity: 0;
				}
			}

			&Post {
				padding-top: 0.2em;
				padding-bottom: 0.2em;
				padding-left: 0.25em;
				padding-right: 0.35em;
				color: inherit;
				border-bottom: 3px solid black;
			}
			&Post--noResults {
				color: fade( black, 35 );
			}
			&PostTitle { // truncate long titles with ...
				overflow: hidden;

				& > span {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
			&PostArrowIcon {
				margin-left: 1em;
			}
		}

		.OvLabel2 {
			margin-right: 0.5em;
			flex-shrink: 0;
		}
		.MhSpinner {
			height: 1em; width: 1em;
			transform: translateY(0.1em);

			circle {
				stroke-width: 0.175em;
			}
			path {
				stroke-width: 0.175em;
			}
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
